@media (pointer:none), (pointer:coarse) {

  /* source: https://stackoverflow.com/a/50302305/948918 */
  #drag-bar {
    width: 12px;
  }
  #drag-ghost {
    opacity: 0.2;
    background-color: #000;
  }
}
